:root {
  --header-route-active: #fca311;
  --header-route: #fff;
}

:root[data-theme="dark"] {
  --header-route-active: var(--blue5);
}

.header {
  max-width: var(--content-width);
  margin: 0 auto;
}

/* Must increase value of selector because of ".bp3-dark a" */
.header .header__logo {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: inherit;
}

.header__logo:hover {
  text-decoration: none;
}

.header__logo img {
  height: 30px;
  margin-right: 10px;
}

#root .header a {
  color: var(--header-route);
}

#root .header .header__route {
  border-bottom: 3px solid transparent;
  margin-right: 20px;
  color: var(--header-route);
  text-decoration: none;
}

#root .header .header__route:hover {
  color: var(--header-route-active);
}

#root .header .header__route--active {
  color: var(--header-route-active);
  border-bottom: 3px solid var(--header-route-active);
}

/**
 * Blueprintjs is not responsive so this is very hacky
 * (or use flex-wrap)
 */
@media (max-width: 600px) {
  #root .bp3-navbar {
    padding: 5px 2px;
    height: inherit;
  }
  #root .header .bp3-navbar-group.bp3-align-left {
    display: inline-block;
    float: none;
  }
  #root .header .bp3-navbar-divider {
    display: inline-block;
    margin: 0;
  }
}
