:root {
  --content-width: 1200px;
  --background-color: #f2f2f2;
  --main-background-color: #f2f2f2;
  /* --background-color: #f5f5f5; */
  /* --main-background-color: #f5f5f5; */
  --text-color: #212529;
}

:root[data-theme='dark'] {
  --background-color: #151517;
  --main-background-color: #151517;
  --text-color: #d7d7db;
}

html {
  overflow-y: scroll;
}

body {
  background: var(--background-color);
}

.main-wrapper {
  padding: 20px 0;
}

.main {
  max-width: var(--content-width);
  margin: 0 auto;
  background: var(--main-background-color);
  /* padding: 20px; */
  /* box-shadow: 0 3px 8px 0 #00000005; */
  /* border-radius: 2px; */
}

.width-100 {
  width: 100%;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.white-space-pre {
  white-space: pre;
}

.flex-columns {
  display: flex;
}

.flex-columns > div {
  flex: 1;
}

.flex-columns--20 > div {
  padding: 20px;
}

.flex-columns--20 > div:first-child {
  padding-left: 0;
}

.flex-columns--20 > div:last-child {
  padding-right: 0;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.flex-wrap {
  flex-wrap: wrap;
}

.font-size-0 {
  font-size: 0;
}

.no-arrows input::-webkit-outer-spin-button,
.no-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows input[type='number'] {
  -moz-appearance: textfield;
}

.no-wrap {
  white-space: nowrap;
}

.word-break-all {
  word-break: break-all;
}

.dl1 dt {
  font-family: monospace;
  margin-top: 10px;
  text-transform: uppercase;
  padding: 5px 0;
}

.dl1 dd {
  margin-left: 10px;
}

.institutes {
  display: flex;
  justify-content: space-between;
  /* margin-top: 200px; */
  /* position: absolute; */
  bottom: 0;
}

.institutes img {
  max-height: 70px;
}

@media (max-width: 600px) {
  .institutes {
    flex-direction: column;
    align-items: center;
  }
  .institutes * {
    margin: 10px 0;
  }
}

.text-align-center {
  text-align: center;
}

#root td.right {
  text-align: right;
}

.about-people li {
  margin-bottom: 20px;
}

.overflow-x {
  overflow-x: auto;
}

.padding-gaps-20 > * {
  padding: 0 20px;
}

.padding-gaps-20 > *:first-child {
  padding-left: 0;
}

.padding-gaps-20 > *:last-child {
  padding-right: 0;
}

.text-columns-2 {
  column-count: 2;
  column-gap: 50px;
}
