.link-with-icon {
  /* display: inline-flex; */
  /* align-items: center; */
  padding-left: 8px;
  padding-right: 8px;
}

.link-with-icon > span {
  vertical-align: middle;
}

.link-with-icon:first-child {
  padding-left: 0;
}

.link-with-icon .bp3-icon {
  padding-right: 5px;
}
