.email {
  display: inline-flex;
  align-items: center;
}

.email__address {
  /* display: inline-flex; */
  /* align-items: center; */
  display: inline-block;
  margin-left: 10px;
}

.email__envelope {
  margin: 0 5px;
}

.email--no-name .email__envelope {
  margin-left: 0;
}

.email--no-name .email__address {
  margin-left: 0;
}

.email__at {
  display: inline-block;
  padding: 0 4px;
  font-size: 0;
}

.email__at::before {
  font-family: monospace;
  content: "@";
  font-size: 1rem;
}
