:root,
:root[data-theme='light'] {
  --table-details-row-hover: #000;
}

:root[data-theme='dark'] {
  --table-details-row-hover: #fff;
}

.table-details th {
  text-align: right;
  padding-right: 10px;
  font-weight: normal;
  vertical-align: top;
  white-space: nowrap;
}

.table-details tr:hover td,
.table-details tr:hover th {
  color: var(--table-details-row-hover);
}

.table-details--bold th {
  font-weight: 500;
}
